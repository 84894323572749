import React from 'react';
import Layout from '../components/layouts/Layout';
import NewPropertyForm from '../components/newPropertyForm/NewPropertyForm';

const AddProperty = () => {
  return (
    <Layout navColor="white" margined={true}>
      <NewPropertyForm></NewPropertyForm>
    </Layout>
  );
};

export default AddProperty;
